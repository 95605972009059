export enum GA4Event {
  CONTACT_FORM_SENT = 'odeslany-formular',
  APP_PAGE_TYPE_CHANGE_EVENT = 'pageTypeChange',
}

export enum GTMEvent {
  START_EVENT = 'gtm.js',
  APP_TITLE_CHANGE_EVENT = 'titleChange',
}

// prettier-ignore
export type GTMGA4<P> =
  P extends GA4Event.CONTACT_FORM_SENT ? {
      firstName: string,
      lastName: string,
      email: string,
      company: string,
      message: string
    } :
    Record<string, unknown>;

// prettier-ignore
export type EcommerceGTMGA4Result<P> =
// P extends GA4Event.PRODUCT_VIEW ? { items: EcommerceProduct[] } :
  Record<string, unknown>;

import React, { useEffect } from 'react';
import Head from 'next/head';
import { GTM_DEFAULT_DATALAYER_NAME } from './constants';
import { useSelector } from 'react-redux';
import { GoogleTagManager } from './GoogleTagManager';
import { GTMEvent } from './types';
import { selectAppMetaData, selectCurrentPageType } from '../../modules/app/reducer/selectors';
import { isTouchOnly } from '../../utils/browser';

interface Props {
  dataLayerName?: string;
}

export const GtmBody: React.FC<Props> = ({ dataLayerName = GTM_DEFAULT_DATALAYER_NAME }) => {
  const pageType = useSelector(selectCurrentPageType);
  const appMetaData = useSelector(selectAppMetaData);

  const initData = {
    pageType,
    sitelang: 'cz-CZ',
    title: appMetaData.title,
    mobile: isTouchOnly(),
  };

  const startTime = new Date().getTime();

  useEffect(() => {
    // just add it on the end of eventLoop to wait until this component will be certainly mounted
    setTimeout(
      () => GoogleTagManager.updateDataLayer(GTMEvent.START_EVENT, { 'gtm.start': startTime }, dataLayerName),
      0
    );
  }, []);

  // fix me - enable by configuration
  // if (!gtmEnabled) {
  //   return null;
  // }

  return (
    <Head>
      <script
        defer
        dangerouslySetInnerHTML={{
          __html: `
            window.${dataLayerName} = window.${dataLayerName} || [];
            window.${dataLayerName}.push(${JSON.stringify(initData)})`,
        }}
      />
    </Head>
  );
};

import React from 'react';
import Head from 'next/head';
import { setUrlParams } from '../../routing/utils/setUrlParams';

interface Props {
  dataLayerName?: string;
  gtmId?: string;
}

export const GtmScriptCmp: React.FC<Props> = ({ dataLayerName, gtmId }) => {
  if (!gtmId) {
    return null;
  }

  return (
    <Head>
      <script
        defer
        src={setUrlParams('https://www.googletagmanager.com/gtm.js', {
          id: gtmId,
          l: dataLayerName,
        })}
      />
    </Head>
  );
};

export const GtmScript = React.memo(GtmScriptCmp);
